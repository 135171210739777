<template>
  <b-card class="p-1">
    <form-component :page="statute" @onSubmit="onSubmit" @fetchByLocale="fetchGeneralTermsOfSale" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/shared/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, submit } from '@/http/static-pages/statute';
import { createBasicPageForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      statute: {
        lang: '',
        title: '',
        value: '',
        title_image: null,
      },
    };
  },

  methods: {
    fetchGeneralTermsOfSale(lang = 'pl') {
      show(lang)
        .then(async ({ data: { data } }) => {
          if (!data) return;

          this.statute = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const form = createBasicPageForm(data);

      submit(form)
        .then(() => {
          this.showSuccessNotification('Dane zapisane', 'Strona została zapisana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
